import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CompanyAdminGuard } from './guards/company-admin.guard';
import { NoAuthGuard } from './guards/no-auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: (): Promise<any> =>
          import('src/app/modules/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },

  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: (): Promise<any> =>
          import('./modules/home/home.module').then((m) => m.HomePageModule),
      },
    ],
  },
  {
    path: 'command',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/command/command.module').then(
        (m) => m.CommandPageModule
      ),
  },
  {
    path: 'configuration',
    canActivate: [AuthGuard, CompanyAdminGuard],
    canActivateChild: [AuthGuard, CompanyAdminGuard],
    loadChildren: () =>
      import('./modules/configuration/configuration.module').then(
        (m) => m.ConfigurationPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
