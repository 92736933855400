import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _httpClient: HttpClient,
    private _jwtHelper: JwtHelperService
  ) {}

  public signIn(login: string, password: string): Observable<any> {
    return this._httpClient
      .post<any>('/api/auth/login', { login, password })
      .pipe(tap(this.setSession));
  }

  public signOut(): void {
    localStorage.removeItem('access_token');
  }

  public isLoggedIn(): boolean {
    const token = this.getAuthorizationToken();

    if (!token) {
      return false;
    }
    try {
      const isExpired = this._jwtHelper.isTokenExpired(token);
      return !isExpired;
    } catch (e) {
      return false;
    }
  }

  public isCompanyAdmin(): boolean {
    const user = this.getLoggedUser();

    return user && (user.isAdmin || user.isYaakadevAdmin);
  }

  public isYaakadevAdmin(): boolean {
    const user = this.getLoggedUser();

    return user && user.isYaakadevAdmin;
  }

  public getLoggedUser(): any {
    const token = this.getAuthorizationToken();
    if (!token) {
      return {};
    }
    try {
      return this._jwtHelper.decodeToken(token);
    } catch (e) {
      return {};
    }
  }

  public getAuthorizationToken(): string | null {
    return localStorage.getItem('access_token');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private setSession(authResult: { access_token: string }): void {
    localStorage.setItem('access_token', authResult.access_token);
  }
}
